
import BackPage from "../components/BackPage";

const ConditionPage = () => {
    return (
        <div className="main-container">

            <BackPage />

            <div className="box md:shadow-2xl">
                <div className="flex flex-col items-start text-start">
                    <h2>Condizioni del servizio</h2>
                    <p>TattooSwap, brand indipendente per lo scambio di attrezzature e visibilità tra tatuatori ed estetiste, mette a disposizione degli utenti un servizio (da ora in poi il "Servizio") web based che consente di pubblicare e consultare annunci e inserzioni di soggetti privati o professionali che intendano alienare o acquistare beni o prestare e ricevere servizi. Il Servizio consente agli utenti inserzionisti ed agli utenti interessati a quanto pubblicato di entrare in contatto tra di loro, nonché di completare transazioni di compravendita attraverso il sistema di pagamento integrato nella piattaforma.</p>
                    <h2>Titolarità della piattaforma</h2>
                    <p>Andrea Natale (P.I. 03055500981, C.F. NTLNDR85T29B157I) con sede legale in via Dugali 8, 25080, Padenghe sul Garda (BS) è proprietario e titolare della piattaforma web per il tramite della quale viene gestito il Servizio nonché di tutti i relativi diritti inerenti e conseguenti allo sfruttamento della piattaforma medesima.</p>

                    <h2>Applicabilità delle condizioni</h2>
                    <p>Le presenti Condizioni Generali del Servizio si applicano sia agli utenti che utilizzino il Servizio in consultazione degli annunci pubblicati sia agli utenti inserzionisti privati o professionali (d'ora in poi collettivamente "utente/i").</p>

                    <h2>Termini per l'uso del servizio</h2>
                    <p>L'utilizzo del Servizio è consentito solo ad utenti maggiorenni secondo la legge italiana (maggiori di anni 18). L'eventuale utilizzo del Servizio è concesso altresì agli utenti che abbiano compiuto sedici anni esclusivamente a valle della autorizzazione e comunque sotto la supervisione dei genitori o di chi ne esercita la potestà o la tutela, che assumeranno quindi ogni responsabilità dell'operato del minore nei confronti di TattooSwap e dei terzi ad ogni titolo coinvolti.</p>

                    <p>L'utilizzo del Servizio consente la libera consultazione degli annunci e la creazione di utenze finalizzate all'utilizzo del servizio medesimo. La pubblicazione di inserzioni, la possibilità di relazionarsi con altri utenti del Servizio, incluso l'acquisto e lo scambio di informazioni, le transazioni di pagamento ed altre funzionalità ad esso connesse potranno essere messe a disposizione solamente alle utenze registrate – gratuitamente – al Servizio. Talune funzionalità specifiche del Servizio e le inserzioni ulteriori rispetto alle soglie fissate per talune categorie potranno essere messe a disposizione solamente a pagamento così come da specifiche condizioni di contratto relative ai servizi medesimi.</p>

                    <p>Le transazioni di compravendita tra utenti avvengono esclusivamente attraverso il sistema di pagamento integrato nella piattaforma, gestito dal fornitore di servizi di pagamento Stripe. TattooSwap applica una commissione su ogni transazione completata, il cui importo è indicato nelle pagine dedicate del sito e/o durante il processo di pagamento. Gli utenti si impegnano a non eludere il sistema di pagamento della piattaforma e a non concordare metodi di pagamento alternativi al fine di evitare le commissioni previste.</p>

                    <p>Fatta eccezione per le transazioni di pagamento gestite attraverso la piattaforma, le relazioni intrattenute tra gli utenti del Servizio, incluso lo scambio di informazioni, anche per il tramite del form di risposta all'annuncio, e la consegna dei beni o servizi, avvengono esclusivamente tra utenti senza che TattooSwap sia parte della relazione. L'utenza si impegna, altresì, a non fare utilizzo improprio dei contatti presenti a qualunque titolo sulla piattaforma di TattooSwap. A titolo esemplificativo ma non esaustivo è vietato l'invio di pubblicità, materiale promozionale, o qualsiasi altra forma di sollecitazione non autorizzata o non richiesta tramite e-mail o con qualsiasi altro metodo di contatto.</p>

                    <p>Gli utenti manterranno la titolarità di tutti diritti di proprietà relativi a video, fotografie, fermo immagini, audio o altro materiale (di seguito, le "Riproduzioni") che TattooSwap ha consentito agli stessi di pubblicare sul proprio sito. Fermo restando quanto precede, l'utente concede a TattooSwap una licenza non esclusiva, gratuita, perpetua, trasferibile, irrevocabile e senza limiti di territorio, per utilizzare (anche in combinazione con altri materiali audio e/o video e/o fotografici) e, dunque, fissare, all'interno del sito e/o su ogni altro mezzo (sia esso digitale, elettronico o cartaceo), riprodurre, modificare, adattare, tradurre, distribuire, pubblicare, visualizzare, riprodurre in pubblico le Riproduzioni dell'utente, impegnandosi peraltro a non opporsi alla pubblicazione, utilizzo, modifica, cancellazione e sfruttamento delle Riproduzioni da parte di TattooSwap.</p>

                    <p>Con l'utilizzo del Servizio, gli utenti si assumono l'esclusiva responsabilità circa il diritto di utilizzo delle Riproduzioni pubblicate dagli stessi sul sito, manlevando e tenendo indenne TattooSwap da qualsiasi pretesa e/o richiesta di risarcimento formulata da soggetti terzi a fronte della violazione dei diritti di quest'ultimi.</p>

                    <p>Al momento della registrazione sul sito da parte dell'utente, quest'ultimo sarà tenuto a fornire i propri dati anagrafici reali senza ricorrere all'utilizzo di indirizzi email temporanei o alias (ovvero indirizzi associati al proprio indirizzo email dal quale però non è possibile inviare email ma solo riceverle). Resta espressamente inteso che, in caso di mancata osservanza delle disposizioni della presente clausola da parte dell'utente, TattooSwap sarà considerata liberata dell'onere di fornire taluni servizi di assistenza allo stesso, essendo questa impossibilitata alla verifica della corrispondenza utente-email.</p>

                    <p>Con l'utilizzo del Servizio, gli utenti autorizzano TattooSwap a fornire i propri dati personali di contatto quali email e numero di telefono ad altri utenti, previa richiesta tramite form di risposta all'annuncio e successiva accettazione da parte di entrambi gli utenti.</p>

                    <h2>Sistema di pagamento</h2>
                    <p>TattooSwap si avvale del servizio di elaborazione pagamenti fornito da Stripe per gestire le transazioni tra gli utenti della piattaforma. Per utilizzare il sistema di pagamento, gli utenti dovranno accettare anche i termini e le condizioni di Stripe, disponibili sul sito web del fornitore di servizi di pagamento.</p>

                    <p>TattooSwap trattiene una commissione su ogni transazione completata attraverso la piattaforma. La percentuale e/o l'importo della commissione sono indicati in modo chiaro durante il processo di acquisto e possono essere soggetti a variazioni, che verranno comunicate agli utenti con adeguato preavviso.</p>

                    <p>In caso di controversie relative a pagamenti o transazioni, gli utenti si impegnano a contattare TattooSwap prima di intraprendere qualsiasi azione nei confronti dell'altro utente o del fornitore di servizi di pagamento. TattooSwap si riserva il diritto di intervenire nelle controversie tra utenti e, a sua discrezione, di trattenere temporaneamente i fondi relativi a una transazione contestata fino alla risoluzione della controversia.</p>

                    <p>Gli utenti venditori si impegnano a spedire i prodotti o a fornire i servizi solo dopo la conferma del pagamento da parte di TattooSwap. Gli utenti acquirenti si impegnano a confermare la ricezione del prodotto o del servizio attraverso la piattaforma, consentendo così il rilascio del pagamento al venditore.</p>

                    <h2>Responsabilità dell'utente</h2>
                    <p>L'utente è totalmente ed esclusivamente responsabile dell'uso del Servizio (da intendersi espressamente con riguardo alle funzioni di pubblicazione, di consultazione, di gestione delle inserzioni, di contatto tra utenti e di completamento delle transazioni) ed è pertanto l'unico garante e responsabile dei beni e dei servizi offerti per il tramite del Servizio nonché della correttezza, completezza e liceità delle inserzioni e del proprio comportamento nell'ambito del contatto tra utenti.</p>

                    <p>L'utente garantisce la disponibilità e/o la titolarità del bene/servizio oggetto delle inserzioni medesime. L'utente garantisce altresì che i propri annunci non violano alcun diritto d'autore né diritto di proprietà industriale né altro diritto di terzi. In caso di contestazione da parte di terzi riguardo a qualsiasi annuncio o condotta ad esso legata, l'utente se ne assume la piena responsabilità e si impegna a tenere manlevata e indenne TattooSwap da qualsiasi danno, perdita o spesa.</p>

                    <p>L'utente si impegna ad utilizzare il form di risposta all'annuncio al solo scopo di prendere contatto e scambiare informazioni con gli altri utenti relativamente agli annunci, utilizzando un linguaggio consono, nel rispetto della legge, dell'etica e della netiquette. In ogni caso, l'Utente si impegna a rispettare con qualsiasi interlocutore le norme di buona educazione e l'utilizzo di un linguaggio consono, sia online sia offline. TattooSwap si riserva in ogni momento di adire le competenti autorità, qualora dovesse ravvisare, nelle comunicazioni nei confronti del proprio personale, contenuti illeciti, ingiuriosi, minacce o calunnie.</p>

                    <p>L'utente, inoltre, si assume ogni responsabilità per eventuali danni che possano derivare al suo sistema informatico dall'uso del Servizio o a quello di terzi. Resta infine inteso che ogni eventuale utilizzo di robot, spider, scraper e/o ulteriori strumenti automatici per accedere al sito e/o per estrapolare i relativi dati, contenuti, informazioni è espressamente vietato.</p>

                    <h2>Limitazione di responsabilità</h2>
                    <p>TattooSwap non presta alcuna garanzia circa il contenuto, la completezza e la correttezza delle inserzioni pubblicate né con riguardo ai dati pubblicati, né relativamente alle informazioni successivamente fornite dall'utente, né con riferimento al numero o alla qualità dei risultati ottenuti tramite il Servizio. In ogni caso TattooSwap si riserva, in qualsiasi momento, il diritto di valutare, approvare, eliminare o impedire l'inserzione ovvero il diritto di inibire la consultazione o il contatto per il tramite del form di risposta all'annuncio nel caso in cui, a proprio insindacabile giudizio, l'uso del Servizio da parte dell'utente si riferisca a particolari sezioni merceologiche o possa considerarsi lesivo di diritti o delle prerogative di TattooSwap o di terzi. Resta inteso che anche in caso di valutazione ed approvazione delle inserzioni (siano esse automatiche o manuali) TattooSwap non presta alcuna garanzia circa il contenuto, la completezza e la correttezza delle stesse.</p>

                    <p>Con riferimento alle transazioni di pagamento effettuate attraverso la piattaforma, TattooSwap agisce esclusivamente come intermediario tecnologico che facilita l'incontro e lo scambio tra domanda e offerta. TattooSwap non garantisce in alcun modo la qualità, la sicurezza o la legalità dei prodotti o servizi offerti, né la capacità degli utenti di portare a termine le transazioni. Gli utenti riconoscono ed accettano che TattooSwap non potrà essere ritenuta responsabile per eventuali difetti, danni o problemi relativi ai prodotti o servizi scambiati sulla piattaforma.</p>

                    <p>In caso di controversie relative alle transazioni, TattooSwap potrà, a sua discrezione, intervenire per facilitare la risoluzione della controversia, ma non ha alcun obbligo di intermediazione o di risoluzione. TattooSwap potrà anche trattenere temporaneamente i fondi di una transazione contestata fino alla risoluzione della controversia, senza che ciò costituisca un'ammissione di responsabilità da parte di TattooSwap.</p>

                    <p>TattooSwap è altresì estranea alle trattative eventualmente nascenti dall'uso del Servizio e pertanto non garantisce né la bontà né l'esito delle stesse, di conseguenza nessuna richiesta di restituzione, compensazione, riparazione e/o risarcimento a qualunque titolo potrà essere indirizzata nei confronti di TattooSwap, fatto salvo quanto specificamente previsto nella sezione "Sistema di pagamento" delle presenti Condizioni del Servizio.</p>

                    <p>Il Servizio è offerto per il tramite del sito www.tattooswap.it e delle applicazioni mobile che possono contenere banner/link ad altri siti Internet o applicazioni che non sono sotto il controllo di TattooSwap; la pubblicazione dei predetti banner/link non comporta l'approvazione o l'avallo da parte di TattooSwap dei relativi siti e dei loro contenuti, né implica alcuna forma di garanzia da parte di quest'ultima che pertanto non si assume alcuna responsabilità. L'utente riconosce, quindi, che TattooSwap non è responsabile, a titolo meramente esemplificativo, della veridicità, correttezza, completezza, del rispetto dei diritti di proprietà intellettuale e/o industriale, né risponde della loro eventuale contrarietà all'ordine pubblico, al buon costume e/o alla morale.</p>

                    <h2>Limitazioni dell'erogazione del servizio</h2>
                    <p>TattooSwap si riserva il diritto di modificare, sospendere o interrompere, in tutto o in parte, il Servizio in qualsiasi momento anche senza preavviso e senza che la stessa sia tenuta ad indicare le ragioni sottese alle predette azioni. TattooSwap in ogni caso non garantisce né l'autenticità del prodotto/servizio in vendita né la veridicità del contenuto del contatto e non potrà quindi in nessun caso essere ritenuta parte o responsabile dello scambio di comunicazioni tra gli utenti e delle trattative da esse nascenti. TattooSwap non potrà parimenti essere ritenuta responsabile dei danni conseguenti alla mancata prestazione del Servizio oggetto del presente contratto a causa dell'errato o mancato funzionamento dei mezzi elettronici di comunicazione per cause estranee alla sfera del proprio prevedibile controllo.</p>

                    <p>A titolo esemplificativo, ma non esaustivo, il malfunzionamento dei server ed altri dispositivi elettronici anche non facenti parte integrante della rete Internet, malfunzionamento dei software installati, virus informatici, nonché da azioni di hacker o altri utenti aventi accesso alla rete.</p>

                    <p>In caso di interruzione del servizio di pagamento, TattooSwap si impegna a completare le transazioni in corso, laddove possibile, o a fornire adeguate informazioni agli utenti coinvolti in transazioni attive al momento dell'interruzione.</p>

                    <h2>Pubblicazione seriale di annunci e/o per conto terzi</h2>
                    <p>È espressamente vietato, salvo esplicita autorizzazione da parte di TattooSwap:</p>
                    <ul>
                        <li>l'utilizzo di sistemi automatici di caricamento annunci, salvo quelli espressamente autorizzati da TattooSwap;</li>
                        <li>la pubblicazione seriale e/o la gestione di annunci per conto terzi con ogni mezzo o modalità;</li>
                        <li>rivendere a terzi i servizi di TattooSwap.</li>
                    </ul>

                    <h2>Limitazioni al contenuto delle pubblicazioni ed informazioni connesse al profilo dell'utente</h2>
                    <p>L'utente si impegna a non falsificare la propria identità ed a rispettare tutte le disposizioni di legge vigenti nonché le Regole di TattooSwap, che a tal fine l'utente accetta e riconosce di dover visionare prima dell'inserimento di ogni annuncio o di prendere contatto con un altro utente per il tramite del form di risposta o la funzione Messaggi.</p>

                    <p>L'utente si impegna altresì, a non utilizzare il Servizio per la pubblicazione, trasmissione, scambio di materiale illecito, volgare, osceno, calunnioso, diffamatorio, offensivo della morale corrente, o, comunque, lesivo dei diritti altrui o di messaggi incitanti all'odio ed alla discriminazione razziale o religioso. Inoltre, l'utente si impegna a non utilizzare il Servizio in maniera tale da violare diritti di proprietà intellettuale o industriale di TattooSwap o di terzi.</p>

                    <p>L'utente prende atto ed accetta che il Servizio richiederà, per finalità di revisione e sicurezza, il completamento di una specifica procedura di identificazione prima di procedere alla pubblicazione degli annunci o per accedere al Servizio e/o continuare ad usufruire dello stesso.</p>

                    <p>A tal proposito l'utente prende atto e accetta che ogni annuncio inserito riporterà obbligatoriamente alcune delle informazioni di cui sopra. Dette informazioni non devono interferire/pregiudicare/influenzare le trattative eventualmente nascenti dall'uso del Servizio per le quali TattooSwap resta estranea.</p>

                    <p>Per utilizzare il sistema di pagamento integrato, gli utenti potrebbero dover fornire ulteriori informazioni e documenti di identificazione richiesti dal fornitore di servizi di pagamento Stripe, in conformità con le normative antiriciclaggio e le politiche di verifica dell'identità applicabili.</p>

                    <h2>Pubblicazioni ulteriori</h2>
                    <p>L'utente riconosce ed accetta la possibilità offerta da TattooSwap di far pubblicare gli annunci anche su altri siti internet o sulle testate appartenenti a soggetti terzi interessati a promuovere e/o pubblicare gli annunci dell'utente. L'utente acconsente inoltre all'utilizzo del contenuto dell'annuncio (foto, testo, ecc.) da parte di TattooSwap al fine della pubblicazione dello stesso su siti terzi, riviste on line o cartacee o altri media, impegnandosi altresì a tenere indenne e manlevare TattooSwap da qualsiasi responsabilità e/o richiesta al riguardo.</p>

                    <p>TattooSwap vieta l'utilizzo da parte di software/applicazioni/siti cd. aggregatori e/o terzi, non espressamente e previamente autorizzati, di qualunque contenuto afferente al Servizio. Ogni violazione al predetto divieto potrà essere perseguita a norma di legge.</p>

                    <h2>Valutazione dell'utente</h2>
                    <p>Ciascun utente, al termine di una transazione conclusa attraverso la piattaforma e/o relativa cancellazione del proprio annuncio a valle di una vendita raggiunta attraverso la piattaforma messa a disposizione da TattooSwap, può recensire un altro utente e lasciare un proprio commento. La valutazione sull'utente è finalizzata ad esprimere un giudizio sulle modalità con cui è avvenuta la transazione e a segnalare il buon fine della stessa.</p>

                    <p>In considerazione di quanto sopra, non è possibile lasciare la propria valutazione nei confronti di utenti con cui l'utente non ha concluso alcuna transazione.</p>

                    <p>L'utente che acquista è libero di raccomandare o meno l'utente inserzionista dopo ogni transazione e viceversa.</p>

                    <p>Il numero e la media delle valutazioni nonché gli eventuali commenti lasciati dagli utenti, saranno pubblicati in ordine cronologico e mostrati in corrispondenza al profilo dell'utente e ai rispettivi annunci.</p>

                    <p>Nel caso in cui l'utente dovesse cancellare il proprio account, il nome dell'utente che ha lasciato la valutazione non sarà più visibile e apparirà l'indicazione che la valutazione proviene da un utente cancellato. Ogni eventuale commento di tale utente verrà rimosso definitivamente e non sarà pertanto più disponibile.</p>

                    <p>TattooSwap potrebbe controllare o moderare contenuti palesemente offensivi afferenti alle valutazioni prima della loro pubblicazione. L'utente resta in ogni caso responsabile dei contenuti inseriti e pubblicati e ha l'obbligo di conformarsi alle presenti Condizioni di Servizio nonché al rispetto della netiquette.</p>

                    <p>TattooSwap può, in ogni caso, intervenire sulla base delle segnalazioni effettuate dagli utenti e rimuovere i contenuti offensivi, illeciti e non veritieri. Parimenti, TattooSwap ha il diritto di intervenire su recensioni e contenuti ritenuti, ad insindacabile giudizio di quest'ultima, non pertinenti con la transazione avvenuta ovvero discordanti con quanto effettivamente emerso e/o riportato.</p>

                    <p>È espressamente vietato qualsiasi compenso in cambio di apprezzamenti e/o valutazioni positive a favore di altri utenti.</p>

                    <h2>Rimborsi e protezione acquirenti</h2>
                    <p>TattooSwap offre un programma di protezione acquirenti per le transazioni effettuate attraverso il sistema di pagamento integrato nella piattaforma. Gli acquirenti possono richiedere un rimborso nei seguenti casi:</p>
                    <ul>
                        <li>Il prodotto non è stato ricevuto entro il termine concordato</li>
                        <li>Il prodotto ricevuto è sostanzialmente diverso da quanto descritto nell'annuncio</li>
                        <li>Il prodotto ricevuto presenta difetti significativi non dichiarati nell'annuncio</li>
                    </ul>

                    <p>La richiesta di rimborso deve essere presentata entro 14 giorni dalla data prevista per la consegna o dalla data di ricezione del prodotto. TattooSwap esaminerà la richiesta e, a sua discrezione, potrà richiedere prove documentali o fotografiche a supporto della richiesta.</p>

                    <p>Se la richiesta di rimborso viene accettata, l'acquirente dovrà restituire il prodotto al venditore prima di ricevere il rimborso, salvo diversa indicazione di TattooSwap. Le spese di restituzione sono a carico dell'acquirente, salvo diverso accordo tra le parti o diversa decisione di TattooSwap.</p>

                    <p>I venditori si impegnano a cooperare in buona fede nella risoluzione delle controversie e accettano le decisioni di TattooSwap in merito alle richieste di rimborso. TattooSwap si riserva il diritto di addebitare al venditore i costi relativi ai rimborsi effettuati, incluse le commissioni di elaborazione dei pagamenti.</p>

                    <h2>Giurisdizione, legge applicabile e foro competente</h2>
                    <p>I rapporti tra TattooSwap e gli utenti sono regolati dalla legge e dalla giurisdizione italiana, in base alla quale anche le presenti Condizioni Generali dovranno essere interpretate. Salvo quanto disposto da norme di legge non derogabili, il Foro di Reggio Emilia sarà competente in via esclusiva a dirimere ogni controversia riguardante le presenti Condizioni Generali ed i rapporti dalle stesse regolati.</p>

                    <h2>Validità delle presenti Condizioni Generali</h2>
                    <p>Le presenti condizioni Generali di Servizio si considereranno applicabili, ove compatibili, anche in caso di ulteriori, diversi e specifici accordi relativi ai servizi a. TattooSwap potrà comunque apportare unilateralmente in qualsiasi momento modifiche alle presenti Condizioni Generali dandone comunicazione sulla propria piattaforma web.</p>

                    <h2>Modifiche</h2>
                    <p>Le presenti condizioni potrebbero essere soggette a modifiche. In caso di sostanziali modifiche, TattooSwap avviserà l'utente pubblicandole con la massima evidenza sulle proprie pagine o tramite email o altro mezzo di comunicazione.</p>

                    <p>Ultimo aggiornamento: 24/02/2025</p>
                </div>
            </div>
        </div>
    )
}

export default ConditionPage;