
import { useSelector } from 'react-redux';
import stripeLogo from '../../images/stripe.png'
import FetchLoader from "../FetchLoader";
import { useState } from 'react';
import { TealButton } from '../Buttons';
import { Link } from 'react-router-dom';

const PaymentSettings = () => {
    const { id, stripeAccount } = useSelector(state => state.login);
    const token = localStorage.getItem('tattooswap_auth_token');
    // Gestisco la modifica dell'account di Stripe (o completamento di account esistente) generando un altro link
    const [editStripeFetchStatus, setEditStripeFetchStatus] = useState('idle');
    const [editStripeError, setEditStripeError] = useState(null);
    const editStripeAccount = async () => {
        setEditStripeFetchStatus('loading');
        const url = `${process.env.REACT_APP_BASE_URL}/swaps/create-onboarding-session`;
        const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
        const options = { method: 'POST', headers, body: JSON.stringify({ userId: id }) };
        try {
            const response = await fetch(url, options);
            if (response.ok) {
                const result = await response.json();
                console.log('result: ', result);
                window.location.href = result.onboardingUrl;
            } else {
                const error = await response.json();
                throw error
            }
        } catch (error) {
            setEditStripeFetchStatus('failed');
            setEditStripeError(error.message || "Errore da parte del server. Riprova.")
        }
    }
    return (
        <div className="w-full flex flex-col items-start border p-4 rounded shadow-lg">
            <div className="text-lg font-bold text-slate-700 flex gap-2 mb-4"><div className="mt-0.5"><i className="fi fi-br-euro text-teal-400"></i></div>Impostazione pagamenti</div>

            <img src={stripeLogo} alt="Logo Stripe" className="w-[100px]" />
            <p>Tattooswap utilizza <b>Stripe</b> per la gestione dei pagamenti, assicurando agli utenti piena trasparenza e sicurezza sulle transazioni.</p>
            {
                /* CREATO MA IN ATTESA DI COMPLETAMENTO ONBOARDING */
                stripeAccount && stripeAccount.status === 'Pending' &&
                <div className="w-full border-2 border-yellow-500 rounded p-2 text-start">
                    <p className="">Il tuo account Stripe è stato creato ma <b className='text-red-500'>non hai ancora terminato la procedura di attivazione</b>.</p>
                    <p>Termina la configurazione del tuo account Stripe per poter ricevere pagamenti:</p>
                    {editStripeFetchStatus === 'loading' && <FetchLoader />}
                    {editStripeFetchStatus === 'idle' && <TealButton text="Termina configurazione Stripe" click={editStripeAccount} />}
                </div>
            }
            {
                /* ATTIVO */
                stripeAccount && stripeAccount.status === 'Verified' &&
                <div className="w-full border-2 border-green-500 rounded p-2 text-start">
                    <p className="text-[#635AFF] font-bold">Il tuo account è abilitato per ricevere pagamenti.</p>
                    <p>Puoi <span className="underline text-teal-500 cursor-pointer" onClick={editStripeAccount}>modificare il tuo account Stripe</span>.</p>
                    {editStripeFetchStatus === 'loading' && <FetchLoader />}
                    {editStripeFetchStatus === 'failed' && editStripeError && <div className="text-red-500">{editStripeError}</div>}
                </div>
            }
            {
                /* NON ATTIVATO */
                !stripeAccount &&
                <div className="w-full border-2 border-red-500 rounded p-2 text-start">
                    <p className="underline">Non hai un <b>account Stripe attivo</b>.</p>
                    <p>Configura il tuo account Stripe per poter ricevere pagamenti:</p>
                    <Link to="/payments-onboarding"><TealButton text="Attiva il tuo account Stripe" /></Link>
                </div>
            }
            <div className='mt-8 underline cursor-pointer'><Link to="/fee-info">Vai alla pagina di informazioni sulle tariffe di servizio.</Link></div>
        </div>
    )
}

export default PaymentSettings