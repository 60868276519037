import { useEffect, useState } from 'react';
import { GoHomeButton, PrimaryButton, SecondaryButton } from '../components/Buttons.jsx';
import { Link, redirect, useLocation, useSearchParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { login } from '../redux/loginSlice.js';
import FetchLoader from '../components/FetchLoader.jsx';
import { Helmet } from 'react-helmet-async';


const LoginPage = () => {

    const dispatch = useDispatch();

    const location = useLocation();
    const redirectUrl = decodeURIComponent(location.state?.redirect);

    //----- HANDLE USER INPUT -----//

    const [inputData, setInputData] = useState({ email: '', password: '' });

    const handleInputData = (event) => {
        const { id, value } = event.target;

        setInputData(prevInputData => ({
            ...prevInputData,
            [id]: value
        }))

        if (inputData.email && inputData.password) { setCheckData(false) }
    }

    //----- HANDLE LOGIN REDUX FETCH -----//

    const { isLogged, isLoading, error } = useSelector((state) => state.login);
    const [checkData, setCheckData] = useState(false);

    const sendData = () => {
        if (!inputData.email || !inputData.password) {
            setCheckData(true);
        } else {
            dispatch(login(inputData));
        }
    }

    useEffect(() => {
        if (isLogged) {
            setTimeout(() => {
                if (redirectUrl !== 'undefined') {
                    window.location.href = redirectUrl;
                } else {
                    window.location.href = "/";
                }
            }, 3000)
        }
    }, [isLogged])

    // Gestisco il tasto invio per eseguire la ricerca
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            sendData();
        }
    }

    return (
        <div className="main-container">
            <Helmet>
                <title>Login- Accedi al tuo account su Tattooswap!</title>
                <meta name="description" content="Accedi al tuo account Tattooswap per acquistare o vendere macchinette e attrezzatura usata per tatuatori. Entra ora!" />
                <meta name="keywords" content="login tattooswap, accesso tatuatori, account tatuatori" />
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Login - Accedi al tuo Account su Tattooswap!" />
                <meta property="og:description" content="Accedi al tuo account su Tattooswap e partecipa alla compravendita di attrezzature per tatuatori." />
                <meta property="og:url" content="https://www.tattooswap.it/login" />
                <meta property="og:image" content="https://tattooswap.it/images/tattooswap_line.png" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://www.tattooswap.it/login"></link>
                {/* Info pagina di login */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Login Tattooswap",
                        "description": "Pagina di login per Tattooswap",
                        "mainEntity": {
                            "@type": "LoginAction",
                            "name": "Form di Login Tattooswap",
                            "description": "Modulo per accedere al proprio account su Tattooswap"
                        }
                    })}
                </script>
            </Helmet>
            {!isLogged && <GoHomeButton />}
            <div className="box md:shadow-2xl sm:w-[400px]">

                {
                    isLogged ? (
                        <>
                            <h2 className='text-teal-500'>Bentornat* su Tattooswap!</h2>
                            <h3>Verrai indirizzat* alla pagina desiderata fra 3 secondi...</h3>
                        </>
                    ) : (
                        error ? (
                            error === 'Failed to fetch' ? (
                                <>
                                    <h4 className='mb-4'>Qualcosa è andato storto...</h4>
                                    <SecondaryButton text="Ricarica la pagina" click={() => window.location.reload(true)} />

                                </>
                            ) : (
                                <>
                                    <h4 className='mb-4'>{error}</h4>
                                    <SecondaryButton text="Ricarica la pagina" click={() => window.location.reload(true)} />
                                </>
                            )
                        ) : (
                            <form id="login-form" action="" onSubmit={(event) => { event.preventDefault(); sendData(); }}>
                                <h2 className="mb-4 text-teal-500">Esegui il login</h2>
                                <div className='flex flex-col'>
                                    <label htmlFor="email" className='self-center'>Indirizzo email</label>
                                    <input type="email" id="email" name="email" autoComplete="email" className='w-96' onChange={handleInputData} onKeyDown={handleKeyDown} />
                                </div>
                                <div className='flex flex-col'>
                                    <label htmlFor="password" className='self-center'>Password</label>
                                    <input type="password" id="password" name="password" autoComplete="current-password" className="mb-8 w-96" onChange={handleInputData} onKeyDown={handleKeyDown} />
                                </div>
                                <p className={checkData ? 'text-red-500 mb-4' : 'hidden'}>Riempi tutti i campi.</p>
                                {
                                    isLoading ? (
                                        <div className='w-full flex justify-center'>
                                            <FetchLoader />
                                        </div>
                                    ) : (
                                        <>
                                            <button type="submit" className='bg-slate-500 text-slate-50 px-4 py-3 rounded-lg hover:bg-slate-600 min-w-[150px]'>Accedi</button>
                                            <Link to="/recovery-password"><p className='text-teal-500 underline'>Ho dimenticato la password</p></Link>
                                        </>
                                    )
                                }

                                <div className="border-t mt-8 w-full p-2 text-slate-500">Nuovo su Tattooswap? <Link to='/register'><span className="text-teal-400 underline cursor-pointer">Registrati</span></Link></div>

                            </form>
                        )
                    )
                }

            </div>
        </div>
    )
}

export default LoginPage;
