import { useDispatch, useSelector } from "react-redux";
import { getPositionAsync } from "../../redux/positionSlice";

const GeolocationSettings = () => {
    const dispatch = useDispatch();
    const { isPosition, lat, lng, country, isPositionLoading } = useSelector(state => state.position);
    return (
        <div className="w-full flex flex-col items-start border p-4 rounded shadow-lg">
            <div className="text-lg font-bold text-slate-700 flex gap-2 mb-4"><div className="mt-0.5"><i className="fi fi-rr-location-crosshairs text-teal-400"></i></div>Geolocalizzazione</div>
            {
                isPosition &&
                lat &&
                lng &&
                <div className="flex flex-col items-start w-full">
                    <div className="p-2 border"><b>{lat}, {lng}</b></div>
                    <p className="text-teal-500">{country || ''}</p>
                </div>
            }
            {
                isPosition &&
                (!lat || !lng) &&
                <h3 className="text-teal-500">Non rilevata</h3>
            }
            {/* Integrare il loading di isPositionLoading (non funziona perfettamente il booleano) */}
            {
                !isPosition &&
                <button className={`bg-teal-400 text-slate-50 px-4 py-3 rounded-lg hover:bg-teal-500 flex gap-2 justify-center w-full md:w-fit`} onClick={() => dispatch(getPositionAsync())}>
                    <div className="pt-0.5"><i class="fi fi-rr-location-crosshairs text-white"></i></div>
                    <div>Rileva la mia posizione</div>
                </button>
            }
        </div>
    )
}

export default GeolocationSettings