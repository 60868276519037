import { useEffect, useState } from "react"
import BackPage from "../components/BackPage"
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import FetchLoader from "../components/FetchLoader";


// Questa pagina è l'approdo del pagamento avvenuto
// Questo componente riceve la session_id dalla query e rimanda una chiamata al backend per confermare l'avvenuto pagamento e aggiornare la collection Swaps del database (che altrimenti rimane in sospeso)
const StripeSessionSuccess = () => {
    const token = localStorage.getItem('tattooswap_auth_token');
    const { id } = useSelector(state => state.login);
    const [searchParams] = useSearchParams();
    const swapId = searchParams.get('swapId');
    const sessionId = searchParams.get('session_id');
    const [fetchStatus, setFetchStatus] = useState('idle');
    const [error, setError] = useState(false);
    const sendPaymentConfirmed = async () => {
        setFetchStatus('loading');
        const url = `${process.env.REACT_APP_BASE_URL}/swaps/confirm-payment`;
        const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
        const options = { method: 'PATCH', headers, body: JSON.stringify({ userId: id, swapId, sessionId }) };
        try {
            const response = await fetch(url, options);
            if (response.ok) {
                const result = await response.json();
                setFetchStatus('succeeded');
            } else {
                const error = await response.json();
                throw error;
            }
        } catch (error) {
            setError(error.message || "Errore da parte del server.")
            setFetchStatus('failed');
        }
    }
    useEffect(() => {
        if (id && swapId && sessionId) sendPaymentConfirmed();
    }, [id, swapId, sessionId])


    const goToMyProfile = () => {
        window.location.href = "/my-profile"
    }
    return (
        <div className="main-container">
            {
                fetchStatus === 'succeeded' &&
                <div className="flex flex-col gap-2">
                    <h2 className="text-teal-500">Pagamento avvenuto con successo!</h2>
                    <p>Puoi visualizzare i dati del venditore nella sezione dei tuoi prodotti acquistati.</p>
                    <p className="text-teal-400">Complimenti! Hai contribuito a rendere il mondo tattoo più sostenibile!</p>
                    <div className="flex justify-center items-center border-2 rounded-lg p-4 cursor-pointer bg-white" onClick={goToMyProfile}>
                        <i class="fi fi-rr-arrow-small-left text-3xl mb-[-8px]"></i>
                        <h4>Vai alla tua area riservata</h4>
                    </div>
                </div>
            }
            {
                (fetchStatus === 'loading' || fetchStatus === 'idle') && <FetchLoader />
            }
            {
                fetchStatus === 'failed' && error && <div className="text-red-500">{error}</div>
            }

        </div>

    )
}

export default StripeSessionSuccess