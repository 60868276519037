import { useState, useEffect } from "react";
import FetchLoader from '../FetchLoader.jsx';
import UserAvatar from "../UserAvatar.jsx";
import { Link } from "react-router-dom";
import MainCard from "./MainCard.jsx";
import ProductStatusCard from "./ProductStatusCard.jsx";
import { useSelector } from "react-redux";


// Questo componente esegue la chiamata per ricevere i dati dello swap utilizzando lo swapId passato come props
const SoldProductCard = ({ swapId, isBuyer }) => {
    const token = localStorage.getItem('tattooswap_auth_token');
    const [fetchStatus, setFetchStatus] = useState('idle');
    const [buyerData, setBuyerData] = useState(null);
    // Creo una chiamata direttamente allo swap (che arriverà popolato dei dati dei due utenti) che sostituirà la chiamata seguente e la sorgente di dati in tutto il componente
    const { id } = useSelector(state => state.login);
    const [swapFetchStatus, setSwapFetchStatus] = useState('idle');
    const [swapError, setSwapError] = useState(null);
    const [swapData, setSwapData] = useState(null);
    const getSwapData = async () => {
        setSwapFetchStatus('loading');
        const url = `${process.env.REACT_APP_BASE_URL}/swaps/get-swap-data/${swapId}/${id}`;
        const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
        const options = { method: 'GET', headers };
        try {
            const response = await fetch(url, options);
            if (response.ok) {
                const result = await response.json();
                setSwapData(result.payload);
            } else {
                const error = await response.json();
                throw error
            }
        } catch (error) {
            setSwapError(error.message || 'Errore da parte del server.');
            setSwapFetchStatus('failed');
        }
    }

    useEffect(() => {
        getSwapData();
    }, [])

    return (
        <div className='flex flex-col w-full border p-4 gap-4 rounded shadow-xl'>

            {/* Dettagli prodotto */}
            <div className="flex gap-2 p-2 mb-[-16px]">
                <div><i className="fi fi-rr-box-open text-teal-400"></i></div> <h3>Dettagli prodotto</h3>
            </div>
            {swapData && <MainCard data={[swapData.product._id, swapData.product.coverImage.resizedUrl, swapData.product.title, swapData.product.category, swapData.product.price]} />}


            {/* Status card */}
            <div className="flex gap-2 p-2 mb-[-16px]">
                <div><i className="fi fi-rr-box-open text-teal-400"></i></div> <h3>Informazioni di spedizione</h3>
            </div>
            {swapData && <ProductStatusCard swapData={swapData} userType={isBuyer ? 'Buyer' : 'Supplier'} />}

            {/* Assistenza */}
            {swapData && swapData.status !== 'Sold' && <div className="flex gap-2 p-2 mb-[-16px]">
                <div><i className="fi fi-sr-cloud-question text-teal-400"></i></div> <Link to="/support"><h3 className="underline">Clicca qui per ricevere assistenza su questo scambio</h3></Link>
            </div>}

            {/* Dettagli utente */}
            <div className="flex gap-2 p-2 mb-[-16px]">
                <div><i className="fi fi-rs-file-user text-teal-400"></i></div> <h3>Dati dell'acquirente</h3>
            </div>
            <div className="flex flex-col gap-1 items-start p-4 border rounded">
                <UserAvatar url={isBuyer ? swapData?.supplier.avatarUrl : swapData?.buyer.avatarUrl} />
                <div className="flex gap-2 mt-4">
                    <div className="text-teal-500 font-bold">Id:</div>
                    <div>{isBuyer ? swapData?.supplier._id : swapData?.buyer._id}</div>
                </div>
                <div className="flex gap-2">
                    <div className="text-teal-500 font-bold">Nome:</div>
                    <div>{isBuyer ? swapData?.supplier.name : swapData?.buyer.name}</div>
                </div>
                <div className="flex gap-2">
                    <div className="text-teal-500 font-bold">Cognome:</div>
                    <div>{isBuyer ? swapData?.supplier.surname : swapData?.buyer.surname}</div>
                </div>
                <div className="flex gap-2">
                    <div className="text-teal-500 font-bold">Email:</div>
                    <h3 className="text-teal-700">{isBuyer ? swapData?.supplier.email : swapData?.buyer.email}</h3>
                </div>
                {
                    isBuyer ? (
                        <div className="flex gap-2">
                            <div className="text-teal-500 font-bold">Telefono:</div>
                            <h3 className="text-teal-700">{swapData && !swapData.buyer.phone && "L'utente non ha impostato nessun numero"}</h3>
                            <Link to={`https://wa.me/${swapData?.supplier.phone}`}><h3 className="text-teal-700">{swapData?.supplier.phone} <i className="fi fi-brands-whatsapp"></i></h3></Link>
                        </div>
                    ) : (
                        <div className="flex gap-2">
                            <div className="text-teal-500 font-bold">Telefono:</div>
                            <h3 className="text-teal-700">{swapData && !swapData.supplier.phone && "L'utente non ha impostato nessun numero"}</h3>
                            <Link to={`https://wa.me/${swapData?.buyer.phone}`}><h3 className="text-teal-700">{swapData?.buyer.phone} <i className="fi fi-brands-whatsapp"></i></h3></Link>
                        </div>
                    )
                }

                <h4 className="text-slate-400">Clicca sul numero di telefono per contattare l'utente</h4>

            </div>
        </div>
    )
}

export default SoldProductCard;