import { useEffect, useState } from "react";
import { SecondaryButton, TealButton } from "../Buttons";
import { useSelector } from "react-redux";
import FetchLoader from "../FetchLoader";
import { Link } from "react-router-dom";

const ProductStatusCard = ({ swapData, userType }) => {
    const [statusCopy, setStatusCopy] = useState(null);
    useEffect(() => {
        let copy;
        if (swapData.status === 'Purchased') copy = 'Pagamento avvenuto, in attesa di spedizione.'
        if (swapData.status === 'Shipped') copy = 'Prodotto spedito, in attesa di consegna.'
        if (swapData.status === 'Sold') copy = 'Swap completato! Il prodotto è stato consegnato.'
        setStatusCopy(copy)
    }, [swapData.status])

    // Gestisco l'invio di dati della spedizione da parte del venditore
    const { id } = useSelector(state => state.login);
    const [trackingInput, setTrackingInput] = useState({
        courierCode: null,
        trackingNumber: null
    });
    const handleTrackingInput = (e) => {
        const { id, value } = e.target;
        if (id === 'trackingNumber') {
            setTrackingInput(prevState => ({
                ...prevState,
                trackingNumber: value
            }))
        } else if (id === 'courierCode') {
            setTrackingInput(prevState => ({
                ...prevState,
                courierCode: value
            }))
        }
    }
    const [trackingDataFetchStatus, setTrackingDataFetchStatus] = useState('idle');
    const [trackingDataError, setTrackingDataError] = useState(null);
    const token = localStorage.getItem('tattooswap_auth_token');
    const sendTrackingData = async () => {
        setTrackingDataFetchStatus('loading');
        const url = `${process.env.REACT_APP_BASE_URL}/swaps/set-shipping`;
        const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
        const options = { method: 'PATCH', headers, body: JSON.stringify({ userId: id, swapId: swapData._id, trackingData: trackingInput }) };
        try {
            const response = await fetch(url, options);
            if (response.ok) {
                const result = await response.json();
                console.log('result: ', result);
                setTrackingDataFetchStatus('succeeded');
                setTimeout(() => {
                    window.location.reload()
                }, 3000)
            } else {
                const error = await response.json();
                throw error
            }
        } catch (error) {
            setTrackingDataError(error.message || "Errore da parte del server.");
            setTrackingDataFetchStatus('failed');
        }
    }

    // Gestisco la conferma di ricezione del pacco da parte dell'acquirente
    const [confirmDeilveryFetchStatus, setConfirmDeliveryFetchStatus] = useState('idle');
    const [confirmDeliveryFetchError, setConfirmDeliveryFetchError] = useState(null);
    const sendConfirmDelivery = async () => {
        setConfirmDeliveryFetchStatus('loading');
        const url = `${process.env.REACT_APP_BASE_URL}/swaps/capture-payment/${swapData.sessionId}`;
        const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
        const options = { method: 'PATCH', headers };
        try {
            const response = await fetch(url, options);
            if (response.ok) {
                const result = await response.json();
                console.log('result: ', result);
                setConfirmDeliveryFetchStatus('succeeded');
                setTimeout(() => {
                    window.location.reload()
                }, 3000);
            } else {
                const error = await response.json();
                throw error
            }
        } catch (error) {
            setConfirmDeliveryFetchError(error.message || "Errore da parte del server.");
            setConfirmDeliveryFetchStatus('failed');
        }
    }
    return (
        <div className={`w-full flex flex-col gap-2 p-4 border rounded shadow-xl text-start ${swapData.status === 'Purchased' ? 'border-yellow-500' : swapData.status === 'Shipped' ? 'border-orange-300' : 'border-teal-500'}`}>

            {/* Light */}
            <div className={`w-4 h-4 rounded-full border border-black ${swapData.status === 'Purchased' ? 'bg-yellow-300' : swapData.status === 'Shipped' ? 'bg-orange-300' : 'bg-teal-500'}`}></div>

            {/* swapData.status */}
            <div className="flex gap-2">
                {swapData.status === 'Purchased' && <div>⏳</div>}
                {swapData.status === 'Shipped' && <div>🚛</div>}
                {swapData.status === 'Sold' && <div>🎉</div>}
                <div>{statusCopy}</div>
            </div>


            {/* VENDITORE */}
            {
                swapData.status === 'Purchased' && userType === 'Supplier' &&
                <div className="flex flex-col gap-2">
                    <p className="text-sm text-slate-500">
                        Per garantire la massima trasparenza e sicurezza nella transazione, ti chiediamo di <span className="underline font-bold">inserire i dettagli della spedizione</span>. L'acquirente verrà notificato e potrà tracciare il pacco. Il pagamento verrà sbloccato automaticamente quando confermerà la ricezione.
                    </p>

                    {/* Seleziona corriere */}
                    <div className="flex flex-col gap-2">

                        {/* Corriere */}
                        <div>
                            <h3>Corriere utilizzato</h3>
                            <select name="courierCode" id="courierCode" className="w-full md:w-96" onChange={handleTrackingInput}>
                                <option value={null}>Seleziona corriere</option>
                                <option value="brt">Bartolini BRT</option>
                                <option value="gls">GLS Italy</option>
                                <option value="dhl">DHL Express</option>
                                <option value="sda">SDA Express Courier</option>
                                <option value="ups">UPS</option>
                                <option value="fedex">FedEx</option>
                                <option value="posteitaliane">Poste Italiane</option>
                            </select>
                        </div>

                        {/* Numero di tracking */}
                        <div>
                            <h3>Inserisci numero di tracking</h3>
                            <input type="text" id="trackingNumber" className="w-full md:w-96" onChange={handleTrackingInput} />
                        </div>

                        <div className="mt-4 flex items-center gap-2 p-4 bg-slate-100 rounded-lg">
                            <i className="fi fi-rr-exclamation"></i>
                            <p className="text-sm text-muted-foreground">
                                Ricordati di spedire il prodotto entro 48 ore. Una volta inserito
                                il numero di tracking, l'acquirente potrà monitorare la spedizione
                                e confermare così la ricezione.
                            </p>
                        </div>

                        {
                            trackingDataFetchStatus === 'idle' && trackingInput && trackingInput.courierCode && trackingInput.trackingNumber &&
                            <TealButton text="Conferma spedizione" click={sendTrackingData} />
                        }
                        {
                            trackingDataFetchStatus === 'loading' && <FetchLoader />
                        }
                        {
                            trackingDataFetchStatus === 'failed' && trackingDataError && <div className="text-red-500">{trackingDataError}</div>
                        }
                        {
                            trackingDataFetchStatus === 'succeeded' && <h3>Numero di tracking inserito correttamente. Attendi il refresh.</h3>
                        }

                    </div>
                </div>
            }
            {
                swapData.status === 'Shipped' && userType === 'Supplier' &&
                <div className="flex flex-col gap-2">
                    <h3 className="text-sm text-slate-500">
                        I dati della tua spedizione sono stati memorizzati.
                    </h3>
                    <p>Numero di tracking: <span className="text-teal-500 font-bold">{swapData.tracking.trackingNumber}</span></p>
                    <div>
                        Puoi inserire il numero di tracking per monitorare la spedizione con {swapData.tracking.courierName} su:</div>
                    <Link to={swapData.tracking.trackingLink} target="_blank"><div className="underline cursor-pointer font-bold">{swapData.tracking.trackingLink}</div></Link>


                    <p>E' stato inviato il numero di tracking anche all'acquirente in modo che possa anch'egli monitorare la spedizione.</p>
                    <p className="text-teal-500 font-bold">Il pagamento ti verrà accreditato automaticamente non appena l'acquirente confermerà la ricezione del pacco.</p>

                </div>
            }
            {/* QUI SI POTRA' INSERIRE UNA RECENSIONE AL VENDITORE A SWAP AVVENUTO */}
            {/* {
                swapData.status === 'Sold' && userType === 'Supplier' &&
                <div>Prodotto Swappato!</div>
            } */}


            {/* ACQUIRENTE */}
            {
                swapData.status === 'Purchased' && userType === 'Buyer' &&
                <div className="flex flex-col gap-2">
                    <p className="text-sm text-slate-500">
                        Il venditore sta preparando il tuo ordine e ha <span className="underline font-bold">48 ore di tempo per fornire i dettagli della spedizione</span>. Riceverai una notifica non appena il pacco sarà spedito e potrai tracciarne lo stato. Il pagamento rimarrà bloccato fino a quando non confermerai la ricezione del prodotto.
                    </p>
                </div>
            }
            {
                swapData.status === 'Shipped' && userType === 'Buyer' &&
                <div className="flex flex-col gap-2">
                    <h3 className="text-sm text-slate-500">
                        Il tuo ordine è stato spedito!
                    </h3>
                    <p>Numero di tracking: <span className="text-teal-500 font-bold">{swapData.tracking.trackingNumber}</span></p>
                    <div>
                        Puoi inserire il numero di tracking per monitorare la spedizione con {swapData.tracking.courierName} su:</div>
                    <Link to={swapData.tracking.trackingLink} target="_blank"><div className="underline cursor-pointer font-bold">{swapData.tracking.trackingLink}</div></Link>

                    {confirmDeilveryFetchStatus === 'idle' &&
                        <div className="mt-8 mb-4 flex flex-col gap-2">
                            <div>Una volta ricevuto il pacco, clicca il tasto qui sotto:</div>
                            <TealButton text="Ho ricevuto il prodotto" click={sendConfirmDelivery} />
                            <p className="text-teal-500 font-bold">Il pagamento verrà accreditato automaticamente al venditore non appena confermerai la ricezione del pacco.</p>
                        </div>

                    }
                    {
                        confirmDeilveryFetchStatus === 'loading' && <FetchLoader />
                    }
                    {
                        confirmDeilveryFetchStatus === 'failed' && confirmDeliveryFetchError && <div className="text-red-500">{confirmDeliveryFetchError}</div>
                    }
                    {
                        confirmDeilveryFetchStatus === 'succeeded' && <FetchLoader /> && <h3>Dati inviati. Attendi il refresh.</h3>
                    }

                </div>

            }
            {/* QUI SI POTRA' INSERIRE UNA RECENSIONE AL VENDITORE A SWAP AVVENUTO */}
            {/* {
                swapData.status === 'Sold' && userType === 'Buyer' &&
                <div>Prodotto Swappato!</div>
            } */}


        </div>
    )
}

export default ProductStatusCard