import { useDispatch, useSelector } from 'react-redux';
import { TealButton } from '../components/Buttons'
import stripeLogo from '../images/stripe.png'
import stripeEarth from '../images/stripe-earth.png'
import { setNewStripeAccount } from '../redux/loginSlice';
import { useState } from 'react';
import FetchLoader from '../components/FetchLoader';
import { useNavigate } from 'react-router-dom';
import { setBacklink } from '../redux/backlinkSlice';

const PaymentsOnboarding = () => {
    const token = localStorage.getItem('tattooswap_auth_token');
    const [fetchStatus, setFetchStatus] = useState('idle');
    const [error, setError] = useState(null);
    const { id, email } = useSelector(state => state.login);
    const dispatch = useDispatch();
    const sendFetch = async () => {
        setFetchStatus('loading');
        const url = `${process.env.REACT_APP_BASE_URL}/swaps/create-stripe-account`
        const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
        const options = { method: 'POST', headers, body: JSON.stringify({ userId: id, cEmail: email }) };
        try {
            const response = await fetch(url, options);
            if (response.ok) {
                const result = await response.json();
                console.log('result: ', result)
                dispatch(setNewStripeAccount(result.stripeAccountId));
                createOnboarding();
            } else {
                const error = await response.json();
                throw error
            }
        } catch (error) {
            console.error('error: ', error.message);
            setError(error.message || "Errore da parte del server.");
            setFetchStatus('failed');
            // Ricordati di ricaricare la pagina in caso di errore (in modo che lo status dell'account di Stripe risulti aggiornato)
        }
    }
    const createOnboarding = async () => {
        const url = `${process.env.REACT_APP_BASE_URL}/swaps/create-onboarding-session`
        const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
        const options = { method: 'POST', headers, body: JSON.stringify({ userId: id }) };
        try {
            const response = await fetch(url, options);
            if (response.ok) {
                const result = await response.json();
                window.location.href = result.onboardingUrl;
                setFetchStatus('succeeded');
            } else {
                const error = await response.json();
                throw error
            }
        } catch (error) {
            console.error('error: ', error.message);
            setError(error.message || "Errore da parte del server.");
            setFetchStatus('failed');
        }
    }

    // Link alla pagina termini e condizioni
    const navigate = useNavigate();
    const goToServiceCondition = () => {
        dispatch(setBacklink({ link: "/payments-onboarding", text: "Torna indietro" }));
        navigate("/service-condition");
    }
    return (
        <div className="main-container">
            <div className="box">
                <div className="flex flex-col items-start text-start">
                    <div className='mb-8'>
                        <img src={stripeLogo} alt="Logo Stripe" className="w-[70px]" />
                    </div>
                    <h2>Attivazione del tuo account Stripe per <span className='underline'>inviare e ricevere pagamenti</span></h2>
                    <p className='text-[#635AFF] font-bold'>
                        In fase di attivazione Stripe si occuperà di gestire i tuoi dati di pagamento in modo trasparente e sicuro.
                    </p>
                    <p>Tattooswap è un piccolo team che lavora per offrirti un marketplace di supporto al tuo lavoro nel settore tattoo. Per mantenere la piattaforma attiva, migliorarla costantemente e garantire transazioni sicure, applichiamo le seguenti tariffe di servizio sulle transazioni per gli acquirenti:</p>

                    <div className="my-4 w-full">
                        <table className="w-full border-collapse text-sm">
                            <thead>
                                <tr className="bg-gray-100">
                                    <th className="border px-3 py-2 text-left">Valore del prodotto</th>
                                    <th className="border px-3 py-2 text-left">Percentuale applicata</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border px-3 py-2">fino a 50€</td>
                                    <td className="border px-3 py-2 font-bold">12%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">51€ - 100€</td>
                                    <td className="border px-3 py-2 font-bold">8%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">101€ - 200€</td>
                                    <td className="border px-3 py-2 font-bold">6%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">201€ - 300€</td>
                                    <td className="border px-3 py-2 font-bold">4%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">301€ - 400€</td>
                                    <td className="border px-3 py-2 font-bold">3,6%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">401€ - 500€</td>
                                    <td className="border px-3 py-2 font-bold">3,4%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">501€ - 600€</td>
                                    <td className="border px-3 py-2 font-bold">3,3%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">601€ - 700€</td>
                                    <td className="border px-3 py-2 font-bold">3,2%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">701€ - 800€</td>
                                    <td className="border px-3 py-2 font-bold">3,1%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">801€ - 900€</td>
                                    <td className="border px-3 py-2 font-bold">3,0%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">da 901€</td>
                                    <td className="border px-3 py-2 font-bold">2,9%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p>
                        La tariffa di servizio <span className='font-bold'>è a carico dell'acquirente</span> ed è <span className='font-bold'>applicata al momento del pagamento</span>. Essa include tutti i costi di transazione e permette di vendere in totale sicurezza grazie al nostro sistema di protezione acquirenti e venditori offerto da Stripe.
                    </p>
                    <p>
                        La nostra politica tariffaria è strutturata in modo decrescente: più alto è il valore del prodotto, più bassa sarà la percentuale applicata.
                    </p>
                    <p>
                        <span className='font-bold'>Come venditore riceverai sempre l'importo esatto che hai impostato per il tuo prodotto.</span>
                    </p>
                    <p className="text-[#635AFF]">
                        Inoltre siamo orgogliosi di informarti che <span className='font-bold text-[#635AFF]'>Tattooswap aderisce al programma Stripe Climate</span>: destinerà l'1% dei propri guadagni alla rimozione della CO₂ dall'atmosfera.
                        <img src={stripeEarth} alt="Stripe earth" className='w-12 mt-4' />
                    </p>
                    <p>
                        Procedendo confermi di aver letto e accettato le tariffe del servizio.
                    </p>
                    <p>
                        Trovi i dettagli completi delle nostre condizioni nella sezione <span className='text-teal-500 cursor-pointer' onClick={goToServiceCondition}>Termini e Condizioni</span>.
                    </p>
                    {fetchStatus === 'idle' && <TealButton text="Attiva il mio account Stripe" click={sendFetch} />}
                    {(fetchStatus === 'loading' || fetchStatus === 'succeeded') && <FetchLoader />}
                    {fetchStatus === 'error' && error && <div className='text-red-500'>{error}</div>}
                </div>
            </div>
        </div>
    )
}

export default PaymentsOnboarding