import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

export const register = createAsyncThunk(
    'data/register',
    async (userData) => {

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userData)
        });

        if (response.ok) {
            const result = await response.json();

            localStorage.setItem('tattooswap_auth_token', result.payload.token);
            const userData = result.payload.user;
            return userData;

        } else {
            const errorResult = await response.json();
            throw errorResult;
        }

    });

export const login = createAsyncThunk(
    'login/user',
    async (userData) => {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userData)
        });
        if (response.ok) {
            const result = await response.json();
            localStorage.setItem('tattooswap_auth_token', result.payload.token);
            const userData = result.payload.userData;
            return userData;
        } else {
            const errorResult = await response.json();
            throw errorResult;
        }
    }
)

export const refreshToken = createAsyncThunk(
    'refresh/token',
    async (oldToken) => {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/refresh-token`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${oldToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ oldToken: oldToken })
        });
        if (response.ok) {
            const result = await response.json();
            localStorage.setItem('tattooswap_auth_token', result.payload.newToken);
            const userData = result.payload.userData;
            return userData;
        } else {
            const errorResult = await response.json();
            throw errorResult;
        }
    }
)

// SISTEMARE QUESTO SLICE ELIMINANDO EMAIL E DATI SENSIBILI
const loginSlice = createSlice({
    name: 'login',
    initialState: {
        isLogged: false,
        isLoading: false,
        id: '',
        name: '',
        surname: '',
        email: '',
        phone: null,
        cPhone: '',
        avatarUrl: '',
        basePosition: null,
        products: null,
        rating: null,
        favourites: null,
        lastVisited: null,
        error: null,
        requestsAmount: 0,
        newConversationAlert: '',
        isMarketingAvailable: null,
        stripeAccount: null,
        shippingData: null,
        swapData: null,
        isAdmin: null
    },
    reducers: {
        getLogged: (state, action) => {
            state.isLogged = true;
            state.id = action.payload.id;
            state.name = action.payload.name;
            state.surname = action.payload.surname;
            state.email = action.payload.email;
            state.cPhone = action.payload.cPhone;
            state.avatarUrl = action.payload.avatarUrl;
            state.basePosition = action.payload.basePosition;
            state.products = action.payload.products;
            state.rating = action.payload.rating;
            state.favourites = action.payload.favourites;
            state.lastVisited = action.payload.lastVisited;
            state.newConversationAlert = action.payload.newConversationAlert;
            state.isMarketingAvailable = action.payload.isMarketingAvailable;
            state.stripeAccount = action.payload.stripeAccount;
            state.shippingData = action.payload.shippingData;
            state.swapData = action.payload.swapData;
            state.isAdmin = action.payload.isAdmin;
        },
        getUnlogged: (state) => {
            localStorage.removeItem('tattooswap_auth_token')
            state.isLogged = false;
            state.id = '';
            state.name = '';
            state.surname = '';
            state.email = '';
            state.cPhone = '';
            state.avatarUrl = '';
            state.basePosition = null;
            state.products = null;
            state.rating = null;
            state.favourites = null;
            state.lastVisited = null;
            state.newConversationAlert = '';
            state.isMarketingAvailable = null;
            state.stripeAccount = null;
            state.shippingData = null;
            state.swapData = null;
            state.isAdmin = null;
        },
        clearError: (state) => {
            state.error = null;
        },
        updateFavourites: (state, action) => {
            state.favourites = action.payload.payload.favourites;
        },
        setRequestsAmount: (state, action) => {
            state.requestsAmount = action.payload;
        },
        updateAddingTags: (state, action) => {
            const product = state.products.find(element => element._id === action.payload.productId)
            if (product) {
                product.tags.push(action.payload.newTag);
            }
        },
        updateRemovingTag: (state, action) => {
            const product = state.products.find(element => element._id === action.payload.productId)
            if (product) {
                product.tags = product.tags.filter(element => element !== action.payload.tagToRemove)
            }
        },
        updateRemoveAlert: (state, action) => {
            let indexToRemove = state.newConversationAlert.indexOf(action.payload);
            if (indexToRemove !== -1) {
                const newArray = [...state.newConversationAlert.slice(0, indexToRemove), ...state.newConversationAlert.slice(indexToRemove + 1)]; // coi tre puntini unisce tutto in un unico array e non crea due sottoarray
                state.newConversationAlert = newArray;
            }
        },
        setNewNames: (state, action) => {
            state.name = action.payload.name;
            state.surname = action.payload.surname;
        },
        setNewStripeAccount: (state, action) => {
            state.stripeAccount = {
                id: action.payload,
                status: 'Pending'
            }
        },
        emptyShippingDataForEdit: (state) => {
            state.shippingData = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(register.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isLogged = true;
                state.id = action.payload.id;
                state.name = action.payload.name;
                state.surname = action.payload.surname;
                state.email = action.payload.email;
                state.cPhone = action.payload.cPhone;
                state.avatarUrl = action.payload.avatarUrl;
                state.basePosition = action.payload.basePosition;
                state.products = action.payload.products;
                state.rating = action.payload.rating;
                state.favourites = action.payload.favourites;
                state.lastVisited = action.payload.lastVisited;
                state.newConversationAlert = action.payload.newConversationAlert;
                state.isMarketingAvailable = action.payload.isMarketingAvailable;
                state.isAdmin = action.payload.isAdmin;
                state.error = null;
            })
            .addCase(register.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            })
            .addCase(login.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isLogged = true;
                state.id = action.payload.id;
                state.name = action.payload.name;
                state.surname = action.payload.surname;
                state.email = action.payload.email;
                state.phone = action.payload.phone;
                state.cPhone = action.payload.cPhone;
                state.avatarUrl = action.payload.avatarUrl;
                state.basePosition = action.payload.basePosition;
                state.products = action.payload.products;
                state.rating = action.payload.rating;
                state.favourites = action.payload.favourites;
                state.lastVisited = action.payload.lastVisited;
                state.newConversationAlert = action.payload.newConversationAlert;
                state.isMarketingAvailable = action.payload.isMarketingAvailable;
                state.stripeAccount = action.payload.stripeAccount;
                state.shippingData = action.payload.shippingData;
                state.swapData = action.payload.swapData;
                state.isAdmin = action.payload.isAdmin;
                state.error = null;
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            })
            .addCase(refreshToken.pending, (state) => {
                state.isLoading = true
            })
            .addCase(refreshToken.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isLogged = true;
                state.id = action.payload.id;
                state.name = action.payload.name;
                state.surname = action.payload.surname;
                state.email = action.payload.email;
                state.phone = action.payload.phone;
                state.cPhone = action.payload.cPhone;
                state.avatarUrl = action.payload.avatarUrl;
                state.basePosition = action.payload.basePosition;
                state.products = action.payload.products;
                state.rating = action.payload.rating;
                state.favourites = action.payload.favourites;
                state.lastVisited = action.payload.lastVisited;
                state.newConversationAlert = action.payload.newConversationAlert;
                state.isMarketingAvailable = action.payload.isMarketingAvailable;
                state.stripeAccount = action.payload.stripeAccount;
                state.shippingData = action.payload.shippingData;
                state.swapData = action.payload.swapData;
                state.isAdmin = action.payload.isAdmin;
                state.error = null;
            })
            .addCase(refreshToken.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
                localStorage.removeItem('tattooswap_auth_token')
            })
    }
});

export const { getLogged, getUnlogged, clearError, updateFavourites, setRequestsAmount, updateAddingTags, updateRemovingTag, updateRemoveAlert, setNewNames, setNewStripeAccount, emptyShippingDataForEdit } = loginSlice.actions;

export default loginSlice.reducer;

