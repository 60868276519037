import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { setBacklink } from "../redux/backlinkSlice";
import { useEffect } from "react";

const BackPage = ({ newText, newLink }) => {
    const { link, text } = useSelector(state => state.backlink);
    const dispatch = useDispatch();
    useEffect(() => {
        if (newText || newLink) {
            console.log(0)
            dispatch(setBacklink({ text: newText, link: newLink }))
        }
    }, [newText, newLink])
    return (
        <Link to={link}><div className="flex justify-center items-center border-2 rounded-lg p-4 cursor-pointer bg-white">
            <i class="fi fi-rr-arrow-small-left text-3xl mb-[-8px]"></i>
            <h4>{text}</h4>
        </div></Link>
    )
}

export default BackPage