import { createSlice } from "@reduxjs/toolkit";

const backlinkSlice = createSlice({
    name: 'backlink',
    initialState: {
        link: '/',
        text: 'Torna alla homepage'
    },
    reducers: {
        setBacklink: (state, action) => {
            state.link = action.payload.link;
            state.text = action.payload.text;
        }
    }
})

export const {setBacklink} = backlinkSlice.actions;
export default backlinkSlice.reducer;