import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"
import ProductMiniCard from "../components/ProductMiniCard";
import { TealButton } from "../components/Buttons";
import { useSelector } from "react-redux";
import getShippingPrice from "../components/utils/getShippingPrice";
import FetchLoader from "../components/FetchLoader.jsx";
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

// Questa pagina riceve i dati del prodotto tramite state di useLocation. Sarebbe il caso di ricevere l'id in parmas ed eseguire una chiamata per ricevere i dati dell'oggetto, modo che sia raggiungibile da qualsiasi sezione (anche da un'email)
const ProductPurchase = () => {
    const location = useLocation();
    const productData = location.state;
    // Calcolo l'ammontare della fee
    const [fee, setFee] = useState(null);
    useEffect(() => {
        if (productData) {
            setFee((calculateFee(productData.price)).toFixed(2));
        }
    }, [productData])

    const calculateFee = (productPrice) => {
        if (productPrice <= 50) return productPrice * 0.12;
        if (productPrice <= 100) return productPrice * 0.08;
        if (productPrice <= 200) return productPrice * 0.06;
        if (productPrice <= 300) return productPrice * 0.04;
        if (productPrice <= 400) return productPrice * 0.036;
        if (productPrice <= 500) return productPrice * 0.034;
        if (productPrice <= 600) return productPrice * 0.033;
        if (productPrice <= 700) return productPrice * 0.032;
        if (productPrice <= 800) return productPrice * 0.031;
        if (productPrice <= 900) return productPrice * 0.030;
        return productPrice * 0.029;
    }

    // Calcolo il prezzo totale
    const [totalPrice, setTotalPrice] = useState(null);
    useEffect(() => {
        if (productData && fee) {
            const totalPrice = parseFloat(productData.price) + parseFloat(fee);
            setTotalPrice(parseFloat(totalPrice));
        }
    }, [productData, fee])

    // Avvio il processo di pagamento
    const { id } = useSelector(state => state.login);
    const token = localStorage.getItem('tattooswap_auth_token');
    const [fetchStatus, setFetchStatus] = useState('idle');
    const [error, setError] = useState(null);
    const sendPayment = async () => {
        setFetchStatus('loading');
        const url = `${process.env.REACT_APP_BASE_URL}/swaps/create-checkout-session/${productData._id}`;
        const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
        const options = { method: 'POST', headers, body: JSON.stringify({ userId: id, productPrice: productData.price }) };
        try {
            const response = await fetch(url, options);
            if (response.ok) {
                const result = await response.json();
                console.log('result: ', result);
                window.location.href = result.sessionUrl;
            } else {
                const error = await response.json();
                throw error
            }
        } catch (error) {
            console.error(error.message || 'Errore da parte del server.')
            setFetchStatus('failed');
        }
    }

    return (
        <div className="main-container">
            <div className="box">
                <div className="flex flex-col text-start items-start gap-4">
                    <h2>Riepilogo ordine</h2>

                    <h4 className="text-slate-400">id prodotto: {productData?._id}</h4>



                    {/* Anteprima */}
                    <ProductMiniCard id={productData._id}
                        photo={productData.photo.resizedUrl}
                        title={productData.title}
                        category={productData.category}
                        price={productData.price}
                    />

                    {/* Prezzo prodotto */}
                    <div className="w-full flex justify-between">
                        <div className="">Prezzo prodotto</div>
                        <div className="font-bold">{productData?.price}€</div>
                    </div>
                    {/* Fee */}
                    <div className="w-full flex justify-between">
                        <div className="">Commissioni di servizio</div>
                        <div className="font-bold">{fee}€</div>
                    </div>
                    <div className="text-xs md:w-96 text-teal-500 mt-[-16px]">
                        Questa commissione permette a Tattooswap di mantenere la piattaforma attiva, sostenere i costi del server, migliorarla costantemente, e garantire transazioni sicure utilizzando i servizi Stripe.
                    </div>
                    {/* Spese di spedizione */}
                    <div className="w-full md:w-96 text-xs text-slate-400">
                        <div>Le spese di spedizione sono a carico del venditore.</div>
                    </div>

                    <hr className="w-full" />

                    {/* Totale */}
                    {
                        totalPrice &&
                        <div className="w-full flex justify-between">
                            <div className="">TOTALE</div>
                            <div className="font-bold">{totalPrice}€</div>
                        </div>
                    }


                    <ul className="list-none">
                        <li>✔ Protezione acquirente inclusa</li>
                        <li>✔ Spedizione tracciata</li>
                        <li>✔ Assistenza TattooSwap dedicata</li>
                    </ul>

                    {totalPrice && fetchStatus === 'idle' && <div className="mt-8"><TealButton text="Procedi al pagamento" click={sendPayment} /></div>}
                    {fetchStatus === 'loading' && <div className="mt-8"><FetchLoader /></div>}
                    {fetchStatus === 'error' && error && <div className="text-red-500">{error}</div>}

                </div>
            </div>
        </div>
    )
}

export default ProductPurchase