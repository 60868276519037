import { useEffect, useState } from "react";
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom";
import FetchLoader from "../components/FetchLoader";

const OnboardingSuccess = () => {
    const { id, stripeAccount } = useSelector(state => state.login);
    const [query] = useSearchParams();
    const userIdQuery = query.get('userId');
    const stripeUserIdQuery = query.get('stripeUserId');
    const token = localStorage.getItem('tattooswap_auth_token');
    useEffect(() => {
        if (id && stripeAccount && userIdQuery && stripeUserIdQuery) {
            if (userIdQuery === id && stripeUserIdQuery === stripeAccount.id) {
                updateStatus();
            }
        }
    }, [id, stripeAccount, userIdQuery, stripeUserIdQuery])
    const [fetchStatus, setFetchstatus] = useState('idle');
    const [error, setError] = useState(null);
    const updateStatus = async () => {
        setFetchstatus('loading');
        const url = `${process.env.REACT_APP_BASE_URL}/swaps/set-stripe-status-on-db`;
        const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
        const body = JSON.stringify({ userId: id, newStatus: 'Verified' });
        const options = { method: 'PATCH', headers, body };
        try {
            const response = await fetch(url, options);
            if (response.ok) {
                const result = await response.json();
                console.log('result: ', result);
                setFetchstatus('succeeded');
                setTimeout(() => {
                    window.location.href = `${process.env.REACT_APP_PUBLIC_URL}/my-profile`;
                }, 3000)
            } else {
                const error = await response.json();
                throw error
            }
        } catch (error) {
            setFetchstatus('failed');
            setError(error.message || 'Errore da parte del server.')
        }
    }
    return (
        <div className="w-full flex flex-col items-center py-48 justify-center">
            {
                fetchStatus === 'succeeded' &&
                <div className="text-center">
                    <h3 className="text-[#635AFF]">Account Stripe attivato correttamente</h3>
                    <p>Verrai indirizzat* alla pagina profilo fra 3 secondi.</p>
                </div>
            }
            {
                fetchStatus === 'loading' &&
                <FetchLoader />
            }
            {
                fetchStatus === 'failed' && error &&
                <div className="text-red-500">
                    {error}
                </div>
            }
        </div >
    )
}

export default OnboardingSuccess