import { useEffect, useState } from "react";
import { DisabledButton, PrimaryButton, SecondaryButton, TealButton } from "../Buttons"
import { useDispatch, useSelector } from "react-redux";
import FetchLoader from "../FetchLoader";
import { emptyShippingDataForEdit } from "../../redux/loginSlice";

const ShippingSettings = () => {

    // Controllo se l'utente ha già impostato un indirizzo di spedizione
    const { id, shippingData } = useSelector(state => state.login);
    // Gestisco l'inserimento dei dati di spedizione
    const [shippingInput, setShippingInput] = useState({
        address: null,
        postalCode: null,
        city: null
    })
    const [isCapOk, setIsCapOk] = useState(false);
    const handleInput = (event) => {
        const { id, value } = event.target;
        setShippingInput(prevState => ({
            ...prevState,
            [id]: value
        }))
    }
    useEffect(() => {
        if (shippingInput.postalCode) {
            if (shippingInput.postalCode.length === 5) {
                setIsCapOk(true)
            } else {
                setIsCapOk(false)
            }
        }
    }, [shippingInput])
    // Invio i dati di spedizione
    const token = localStorage.getItem('tattooswap_auth_token');
    const [fetchStatus, setFetchStatus] = useState('idle');
    const [error, setError] = useState(null);
    const sendShippingData = async () => {
        setFetchStatus('loading');
        const url = `${process.env.REACT_APP_BASE_URL}/users/set-shipping-data`;
        const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
        const options = { method: 'POST', headers, body: JSON.stringify({ userId: id, shippingData: shippingInput }) };
        try {
            const response = await fetch(url, options);
            if (response.ok) {
                const result = await response.json();
                setFetchStatus('succeeded');
                setTimeout(() => {
                    window.location.reload();
                }, 3000)
            } else {
                const error = await response.json();
                throw error
            }
        } catch (error) {
            setError(error.message || "Errore da parte del server.")
            setFetchStatus('failed');
        }
    }

    // Svuoto i dati di shippingData in Redux per poterli reimpostare
    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(false);
    const resetShippingData = () => {
        dispatch(emptyShippingDataForEdit());
        setIsEdit(true);
    }

    return (
        <div className={`w-full flex flex-col items-start border p-4 rounded shadow-lg ${!shippingData && 'border-yellow-500'}`}>
            {/* title */}
            <div className="text-lg font-bold text-slate-700 flex gap-2 mb-4"><div className="mt-0.5"><i className="fi fi-rs-truck-side text-teal-400"></i></div>Indirizzo di spedizione</div>
            {/* alert */}
            {
                !shippingData && !isEdit &&
                <div className="bg-amber-50 p-4 rounded-lg mb-4">
                    <p className="text-amber-800 text-sm">
                        ⚠️ È necessario impostare un indirizzo di spedizione per poter effettuare acquisti e vendite sulla piattaforma
                    </p>
                </div>
            }
            {
                shippingData && (!shippingData.address || !shippingData.postalCode || !shippingData.city) &&
                <div className="bg-amber-50 p-4 rounded-lg mb-4">
                    <p className="text-amber-800 text-sm">
                        ⚠️ Dati di spedizione incompleti. È necessario impostare indirizzo, CAP e città per poter effettuare acquisti e vendite sulla piattaforma
                    </p>
                </div>
            }


            {/* Dati */}
            {
                shippingData && shippingData.address && shippingData.postalCode && shippingData.city &&
                <div className="flex flex-col gap-2">
                    <div>{shippingData.address} - {shippingData.postalCode}, {shippingData.city} </div>
                    <SecondaryButton text="Modifica" click={resetShippingData} />
                </div>
            }

            {/* form */}
            {
                (!shippingData || !shippingData.address || !shippingData.postalCode || !shippingData.city) &&
                <>
                    <div className="w-full md:w-[400px] flex flex-col gap-4">
                        <div className="w-full flex gap-2">
                            <input
                                id="address"
                                type="text"
                                placeholder="Via/Piazza"
                                className="w-full p-2 border rounded"
                                onChange={handleInput}
                            />
                        </div>

                        <div className="w-full flex gap-2">
                            <input
                                id="postalCode"
                                type="number"
                                placeholder="CAP"
                                className="w-32 p-2 border rounded"
                                onChange={handleInput}
                            />
                            <input
                                id="city"
                                type="text"
                                placeholder="Città"
                                className="flex-grow p-2 border rounded"
                                onChange={handleInput}
                            />
                        </div>
                        {
                            shippingInput.address &&
                            shippingInput.postalCode &&
                            shippingInput.city &&
                            !isCapOk && <div className="text-sm text-red-500">
                                Il CAP deve essere composto da 5 cifre esatte
                            </div>}
                    </div>
                    <div className="mt-4">
                        {
                            fetchStatus === 'idle' &&
                            shippingInput.address &&
                            shippingInput.postalCode &&
                            shippingInput.city &&
                            isCapOk &&
                            <TealButton text="Salva" click={sendShippingData} />
                        }
                        {
                            (
                                fetchStatus === 'idle' &&
                                !shippingInput.address ||
                                !shippingInput.postalCode ||
                                !shippingInput.city ||
                                !isCapOk
                            ) &&
                            <DisabledButton text="Salva" />
                        }
                        {fetchStatus === 'loading' && <FetchLoader />}
                        {fetchStatus === 'error' && error && <div className="text-red-500">{error}</div>}
                        {fetchStatus === 'succeeded' && <h3>Dati di spedizione aggiornati. Attendere il refresh</h3>}
                    </div>
                </>

            }

        </div>
    )
}

export default ShippingSettings