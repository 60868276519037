import { useState } from "react";
import { Link } from "react-router-dom";
import { TealButton } from "../Buttons";
import { useSelector } from "react-redux";

const BasePositionSettings = () => {
    const { basePosition } = useSelector(state => state.login);
    return (
        <div className="w-full flex flex-col items-start border p-4 rounded shadow-lg">
            {/* title */}
            <div className="text-lg font-bold text-slate-700 flex gap-2 mb-4"><div className="mt-0.5"><i className="fi fi-rr-marker text-teal-400"></i></div>Posizione dei tuoi prodotti sulla mappa</div>

                {
                    basePosition &&
                    basePosition.lat &&
                    basePosition.lng &&
                    <div className="flex flex-col items-start w-full">
                        <div className="p-2 border"><b>{basePosition.lat}, {basePosition.lng}</b></div>
                        <p className="text-teal-500">{basePosition.country || ''}</p>
                        <Link to="/set-products-position?backlink=my-profile"><TealButton text="Modifica posizione prodotti" width="100%" /></Link>
                    </div>
                }
                {
                    basePosition &&
                    (!basePosition.lat || !basePosition.lng) &&
                    <div className="flex flex-col w-full items-start">
                        <h3 className="text-red-400">Posizione dei prodotti non rilevata</h3>
                        <p>Imposta una posizione dei tuoi prodotti per aumentare le possibilità che vengano trovati dagli altri utenti:</p>
                        <Link to="/set-products-position?backlink=my-profile"><TealButton text="Imposta posizione prodotti" width="100%" /></Link>
                    </div>
                }

            
        </div>
    )
}

export default BasePositionSettings