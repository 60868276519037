import { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import FetchLoader from "../components/FetchLoader";
import { GoConversationsButton, SecondaryButton, TealButton } from "../components/Buttons";
import MessageCard from "../components/inboxComponents/MessageCard";
import { useDispatch, useSelector } from "react-redux";
import MessageSendForm from "../components/inboxComponents/MessageSendForm";
import ProductMiniCard from "../components/ProductMiniCard";
import { getUnlogged, updateRemoveAlert } from "../redux/loginSlice";
import { useSearchParams } from 'react-router-dom';
import jwtDecode from "jwt-decode";

const ConversationPage = () => {
    const { id } = useParams();
    const userId = useSelector(state => state.login.id);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Verifico se è presente un token di arrivo dall'email
    const [query] = useSearchParams();
    const emailToken = query.get('emailToken');
    useEffect(() => {
        if (userId && emailToken) {
            try {
                const decodedToken = jwtDecode(emailToken);
                if (decodedToken.userId !== userId) {
                    navigate("/");
                    dispatch(getUnlogged());
                }
            } catch (error) {
                navigate("/");
                dispatch(getUnlogged());
            }
        }
    }, [userId, emailToken])

    // get Conversation data
    const [data, setData] = useState(null);
    const [page, setPage] = useState(1);
    const [fetchStatus, setFetchStatus] = useState('idle');
    const [error, setError] = useState(null)
    const token = localStorage.getItem('tattooswap_auth_token');
    const getConversationData = async () => {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/conversations/get-conversation`;
            setFetchStatus('loading');
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ conversationId: id, page: page })
            });
            if (response.ok) {
                const dataResult = await response.json();
                if (!data) {
                    setData(dataResult);
                } else {
                    // Dalla seconda paginata in poi, aggiunge gli elementi nuovi del campo messages e li aggiunge (prima) di quelli esistenti
                    setData(prevData => ({
                        ...prevData,
                        conversation: {
                            ...prevData.conversation,
                            messages: dataResult.conversation.messages.concat(prevData.conversation.messages)
                        }
                    }))
                }
                setFetchStatus('succeeded');
            } else {
                const errorResult = await response.json();
                setFetchStatus('rejected');
                setError(errorResult)
            }
        } catch (error) {
            setError(error)
        }
    }

    // Se l'errore è il 401 sloggo
    useEffect(() => {
        if (error) {
            if (error.statusCode === 401) {
                localStorage.removeItem('tattooswap_auth_token');
                navigate("/");
                dispatch(getUnlogged());
                // Sistemare questa parte: l'utente viene riloggato in automatico (non dipende da Homepage né da LoginPage)
            }
        }
    }, [error])


    // Set receiverId
    const [receiverId, setReceiverId] = useState(null)
    useEffect(() => {
        if (data) {
            if (userId === data.data.supplierUser._id) {
                setReceiverId(data.data.applicantUser._id)
            } else if (userId === data.data.applicantUser._id) {
                setReceiverId(data.data.supplierUser._id)
            }
        }
    }, [data])

    useEffect(() => {
        if (id && userId) {
            getConversationData()
        }
    }, [id, userId])

    // Handle isMyProduct
    const [isMyProduct, setIsMyProduct] = useState(null)
    useEffect(() => {
        if (data) {
            if (data.data.supplierUser._id === userId) {
                setIsMyProduct(true)
            } else {
                setIsMyProduct(false)
            }
        }
    }, [data])

    // Handle request sended
    const [isRequestSended, setIsRequestSended] = useState(false);
    useEffect(() => {
        if (fetchStatus === 'succeeded') {
            if (data.data.objectReference.saleStatus.status === 'Required') {
                if (data.data.objectReference.saleStatus.pickerId === userId) {
                    setIsRequestSended(true);
                }
            }
        }
    }, [data])

    // Automatic scroll to bottom
    const scrollableRef = useRef();

    useEffect(() => {
        // Posiziona lo scroll in fondo al caricamento della pagina
        if (scrollableRef.current) {
            scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
        }
    }, [data]);

    // Product mini-card click
    const miniCardClick = () => {
        navigate(`/product-page/${data.conversation.objectReference._id}/${data.conversation.objectReference.distance}`)
    }

    // Handle adding page
    useEffect(() => {
        getConversationData();
    }, [page])

    // Remove conversation alert from Redux
    const { newConversationAlert } = useSelector(state => state.login);
    useEffect(() => {
        if (newConversationAlert) {
            removeAlertFetch();
        }
    }, [newConversationAlert])
    const removeAlertFetch = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/conversations/remove-alert?userId=${userId}&conversationId=${id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                dispatch(updateRemoveAlert(id));
            } else {
                const errorResult = await response.json();
                console.log('Fetch error: ', errorResult)
            }
        } catch (error) {
            console.log('Catch error: ', error)
        }
    }

    // Send request fetch
    const [isBuying, setIsBuying] = useState(false)
    const [requestFetchStatus, setRequestFetchStatus] = useState('idle');
    const [requestError, setRequestError] = useState(null);
    const sendRequest = async () => {
        setRequestFetchStatus('loading');
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/products/send-request?productId=${data.conversation.objectReference._id}&pickerId=${userId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const result = await response.json();
                setRequestFetchStatus('succeeded');
                setTimeout(() => {
                    window.location.herf = "/"
                }, 3000)
            } else {
                const errorResult = await response.json();
                setRequestError(errorResult);
                setRequestFetchStatus('error');
            }
        } catch (error) {
            setRequestError(error);
            setRequestFetchStatus('error');
        }
    }

    useEffect(() => {
        if (data?.data) {
            console.log('data.data.objectReference: ', data.data.objectReference)
        }

    }, [data])

    return (
        <div>
            <div className="main-container">
                <GoConversationsButton />
                {data && <h4 className="text-slate-300">Id conversazione: {data.data.conversationId}</h4>}
                <h3 className="mb-[-25px] text-slate-400">Oggetto della conversazione:</h3>
                {data &&
                    <ProductMiniCard
                        id={data.data.objectReference._id}
                        photo={data.data.objectReference.photo.resizedUrl}
                        title={data.data.objectReference.title}
                        category={data.data.objectReference.category}
                        price={data.data.objectReference.price}
                        isMine={isMyProduct ? true : false}
                    />
                }

                {/* Buying object box */}
                {isRequestSended && <h3 className="border-2 border-teal-400 rounded p-2">Hai già inviato una richiesta per questo prodotto.</h3>}
                {
                    !isRequestSended && !isMyProduct && !isBuying &&
                    <div className="flex flex-col">
                        <div>Hai chiarito i tuoi dubbi riguardo questo prodotto? Acquistalo!</div>
                        <TealButton text="Acquista questo oggetto" click={() => { setIsBuying(true) }} />
                    </div>
                }


                {!isMyProduct && isBuying && requestFetchStatus === 'idle' &&
                    <div className="bg-teal-100 p-4 border-2 border-teal-500 rounded flex flex-col items-center">
                        <div className="text-center">
                            <h4>Confermando invierai all'utente proprietario una richiesta per l'acquisto di quest'oggetto.</h4>
                            <p>Se il proprietario dovesse accettare potrà utilizzare il tuo numero di telefono o la tua email per contattarti in modo che vi accordiate in privato per lo scambio e il pagamento.</p>
                        </div>
                        <div className="flex gap-2">
                            <TealButton text="Conferma la richiesta" click={sendRequest} />
                            <SecondaryButton text="Annulla" click={() => { setIsBuying(false) }} />
                        </div>
                    </div>

                }
                {requestFetchStatus === 'loading' && <FetchLoader />}
                {requestFetchStatus === 'succeeded' &&
                    <>
                        <h2>Richiesta di acquisto inviata</h2>
                        <p>Attendi che il proprietario dell'oggetto ti contatti per telefono o via email.</p>
                        <h3>Verrai reindirizzat* in homepage fra 3 secondi.</h3>
                    </>
                }
                {
                    requestFetchStatus === 'error' &&
                    <>
                        <h3>Qualcosa è andato storto. Ricarica la pagina e riprova</h3>
                    </>
                }



                {/* Chat story box */}
                <h3 className="mb-[-25px] text-slate-400">Messaggi scambiati:</h3>
                <div className="flex flex-col w-full p-4 md:w-[800px] rounded gap-2 items-center h-[400px] md:h-[400px] overflow-y-scroll bg-slate-200" ref={scrollableRef}>
                    {fetchStatus === 'succeeded' && <div className="border h-fit w-fit rounded-lg border-slate-700 p-2 cursor-pointer mr-12" onClick={() => setPage(page + 1)}><h4>Messaggi meno recenti...</h4></div>}
                    {fetchStatus === 'idle' || fetchStatus === 'loading' && <FetchLoader />}
                    {fetchStatus === 'error' && <h3>Qualcosa è andato storto, ricarica la pagina e riprova</h3>}
                    {fetchStatus === 'succeeded' &&
                        data &&
                        data.conversation.messages.map((element, index) => {
                            return <MessageCard key={index} userId={userId} messageData={element} />
                        })
                    }

                </div>
                {isMyProduct &&
                    <div className={`mt-[-20px] w-fit rounded flex p-1 bg-slate-200 text-xs justify-center`}>
                        Dopo aver chiarito i dubbi riguardo il prodotto, se l'utente vuole acquistarlo ricordagli di inviarti la richiesta per quell'oggetto (soltanto così verrà tolto dai tuoi prodotti in vendita).
                    </div>}
                {!isMyProduct && <div className={`mt-[-20px] w-fit rounded flex p-1 bg-slate-200 text-xs justify-center`}>
                    Chiarisci ogni dubbio sull'oggetto in vendita. Quando sei convint* puoi inviare una richiesta di acquisto cliccando su "Acquista questo oggetto".
                </div>}

                {/* Input box */}
                {data && receiverId && <MessageSendForm
                    convId={data.data.conversationId}
                    senderId={userId}
                    receiverId={receiverId}
                />}
            </div>
        </div>
    )
}

export default ConversationPage;