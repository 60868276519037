import { SecondaryButton, TealButton } from '../components/Buttons'
import stripeEarth from '../images/stripe-earth.png'
import FetchLoader from '../components/FetchLoader';
import { Link } from 'react-router-dom';

const FeeInfo = () => {

    return (
        <div className="main-container">
            <div className="box">
                <div className="flex flex-col items-start text-start">
                    <p>Tattooswap è un piccolo team che lavora per offrirti un marketplace di supporto al tuo lavoro nel settore tattoo. Per <span className='font-bold'>mantenere la piattaforma attiva</span>, <span className='font-bold'>migliorarla costantemente</span> e <span className='font-bold'>garantire transazioni sicure</span>, applichiamo le seguenti tariffe di servizio sulle transazioni per gli acquirenti:</p>

                    <div className="my-4 w-full">
                        <table className="w-full border-collapse text-sm">
                            <thead>
                                <tr className="bg-gray-100">
                                    <th className="border px-3 py-2 text-left">Valore del prodotto</th>
                                    <th className="border px-3 py-2 text-left">Percentuale applicata</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border px-3 py-2">20€ - 50€</td>
                                    <td className="border px-3 py-2 font-bold">12%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">51€ - 100€</td>
                                    <td className="border px-3 py-2 font-bold">8%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">101€ - 200€</td>
                                    <td className="border px-3 py-2 font-bold">6%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">201€ - 300€</td>
                                    <td className="border px-3 py-2 font-bold">4%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">301€ - 400€</td>
                                    <td className="border px-3 py-2 font-bold">3,6%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">401€ - 500€</td>
                                    <td className="border px-3 py-2 font-bold">3,4%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">501€ - 600€</td>
                                    <td className="border px-3 py-2 font-bold">3,3%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">601€ - 700€</td>
                                    <td className="border px-3 py-2 font-bold">3,2%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">701€ - 800€</td>
                                    <td className="border px-3 py-2 font-bold">3,1%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">801€ - 900€</td>
                                    <td className="border px-3 py-2 font-bold">3,0%</td>
                                </tr>
                                <tr>
                                    <td className="border px-3 py-2">da 901€</td>
                                    <td className="border px-3 py-2 font-bold">2,9%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p>
                        <div className='flex gap-2'>
                            <i className="fi fi-rr-hand-back-point-right text-teal-500"></i>
                            <div>La tariffa di servizio <span className='font-bold'>è a carico dell'acquirente</span> ed è applicata <span className='font-bold'>al momento del pagamento</span>.</div>
                        </div>

                    </p>
                    <p>
                        <div className='flex gap-2'>
                            <i className="fi fi-rr-hand-back-point-right text-teal-500"></i>
                            <div>La nostra politica tariffaria è strutturata in modo decrescente: più alto è il valore del prodotto, più bassa sarà la percentuale applicata.</div>
                        </div>
                    </p>
                    <p>
                        <div className='flex gap-2'>
                            <i className="fi fi-rr-hand-back-point-right text-teal-500"></i>
                            <div>Come venditore riceverai sempre l'<span className='font-bold'>importo esatto</span> che hai impostato per il tuo prodotto.</div>
                        </div>

                    </p>
                    <p className="text-[#635AFF]">
                        Inoltre siamo orgogliosi di informarti che <span className='font-bold text-[#635AFF]'>Tattooswap aderisce al programma Stripe Climate</span>: destinerà l'1% dei propri guadagni alla rimozione della CO₂ dall'atmosfera.
                        <img src={stripeEarth} alt="Stripe earth" className='w-12 mt-4' />
                    </p>
                    <div className='mt-8'><Link to="/my-profile"><SecondaryButton text="Torna al tuo profilo" /></Link></div>
                </div>
            </div>
        </div>
    )
}

export default FeeInfo