import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';

import Footer from './components/Footer.jsx';
import Navbar from './components/Navbar.jsx'

import LoginPage from './pages/LoginPage.jsx';
import RegisterPage from './pages/RegisterPage.jsx';
import SetProductsPosition from './pages/SetProductsPosition.jsx';
import LoggedHomepage from './pages/LoggedHomepage.jsx';
import ProtectedRoute from './hooks/ProtectedRoute.js';
import AddEditProduct from './pages/AddEditProduct.jsx';
import FavoritePage from './pages/FavoritesPage.jsx';
import InboxPage from './pages/InboxPage.jsx';
import ProductPage from './pages/ProductPage.jsx';
import MyProfile from './pages/MyProfile.jsx';
import ScrollToTop from './hooks/ScrollToTop.js';
import { refreshToken } from './redux/loginSlice.js';
import AdminPage from './pages/AdminPage.jsx';
import SupportPage from './pages/SupportPage.jsx';
import RecoveryPassword from './pages/RecoveryPassword.jsx';
import SetNewPassword from './pages/SetNewPassword.jsx';
import ConditionPage from './pages/ConditionPage.jsx';
import PrivacyPage from './pages/PrivacyPage.jsx';
import ConversationPage from './pages/ConversationPage.jsx';
import EnablePositionSupport from './pages/EnablePositionSupport.jsx';
import AllProducts from './pages/AllProducts.jsx';
import { firstCall } from './redux/homepageSlice.js';
import ProductsMapPage from './pages/ProductsMapPage.jsx';
import GoogleAnalytics from './hooks/GoogleAnalytics.js';
import OnboardingSuccess from './pages/OnboardingSuccess.jsx';
import PaymentsOnboarding from './pages/PaymentsOnboarding.jsx';
import ProductPurchase from './pages/ProductPurchase.jsx';
import StripeSessionSuccess from './pages/StripeSessionSuccess.jsx';
import FeeInfo from './pages/FeeInfo.jsx';

function App() {

  const dispatch = useDispatch();
  const token = localStorage.getItem('tattooswap_auth_token');

  // Refresh token e firstCall
  useEffect(() => {
    const currentTime = Date.now() / 1000;
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp > currentTime) {
        dispatch(refreshToken(token));
      } // INSERISCI UN ELSE QUI PER QUANDO IL TOKEN E' SCADUTO (slogga)
    };
    dispatch(firstCall())
  }, [])

  return (
    <Router>
      {/* {!cookie && <CookiesAlert cookie={handleCookie} />} */}
      <Navbar />
      <ScrollToTop />
      <GoogleAnalytics />
      <Routes>
        <Route exact path="/" element={<LoggedHomepage />} />
        <Route exact path="/homepage" element={<LoggedHomepage />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/register" element={<RegisterPage />} />
        <Route exact path="/service-condition" element={<ConditionPage />} />
        <Route exact path="/privacy-policy" element={<PrivacyPage />} />
        <Route exact path="/recovery-password" element={<RecoveryPassword />} />
        <Route exact path="/set-new-password/:token" element={<SetNewPassword />} />
        <Route exact path="/shared-product/:productId" element={<ProductPage />} />
        <Route exact path="/geolocation-support" element={<EnablePositionSupport />} />
        <Route exact path="/all-products" element={<AllProducts />} />

        <Route element={<ProtectedRoute />}>
          <Route exact path="/add-edit-product" element={<AddEditProduct />} />
          <Route exact path="/favorite-products" element={<FavoritePage />} />
          <Route exact path="/products-map" element={<ProductsMapPage />} />
          <Route exact path="/inbox" element={<InboxPage />} />
          <Route exact path="/inbox/conversations/:id" element={<ConversationPage />} />
          <Route exact path="/product-page/:productId" element={<ProductPage />} />
          <Route exact path="/product-purchase/" element={<ProductPurchase />} />
          <Route exact path="/my-profile/" element={<MyProfile />} />
          <Route exact path="/set-products-position" element={<SetProductsPosition />} />
          <Route exact path="/support" element={<SupportPage />} />
          <Route exact path="/admin" element={<AdminPage />} />
          <Route exact path="/payments-onboarding" element={<PaymentsOnboarding />} />
          <Route exact path="/fee-info" element={<FeeInfo />} />
          <Route exact path="/onboarding-success" element={<OnboardingSuccess />} />
          <Route exact path="/payment/success" element={<StripeSessionSuccess />} />
        </Route>

      </Routes>


      {/* <ReduxScreen /> */}
      <Footer />
    </Router>
  )
}

export default App;
