import { useSelector } from "react-redux";
import { GoHomeButton } from "../components/Buttons.jsx";
import SwapCard from "../components/myProfileProducts/SwapCard.jsx";
import { useState, useEffect } from "react";
import MyUserData from "../components/myProfileProducts/MyUserData.jsx";
import MyProfileNavbar from "../components/myProfileProducts/MyProfileNavbar.jsx";
import MainCard from "../components/myProfileProducts/MainCard.jsx";

const MyProfile = () => {
    const reduxUserProducts = useSelector((state) => state.login.products);
    const { swapData } = useSelector(state => state.login);

    // handle section
    const selectedSection = useSelector((state) => state.mySection.selected);

    const [noSalesMessage, setNoSaleMessage] = useState(null);
    const [noPurchaseMessage, setNoPurchaseMessage] = useState(null);
    useEffect(() => {
    }, [noSalesMessage, noPurchaseMessage])
    useEffect(() => {
        if (swapData) {
            if (swapData.sales?.length === 0) setNoSaleMessage("Nessun prodotto venduto.");
            if (swapData.purchases?.length === 0) setNoPurchaseMessage("Nessun prodotto acquistato.");
        }
    }, [swapData, swapData?.sales, swapData?.purchase])
    return (
        <div className="main-container">

            <GoHomeButton />

            <MyProfileNavbar />

            <div className="flex flex-col lg:flex-row gap-2">
                <div className="box gap-8 md:shadow-2xl">

                    {/* PROFILO */}

                    {selectedSection === 0 && <h2>Il mio profilo</h2>}
                    {selectedSection === 0 && <MyUserData />}

                    {/* I TUOI PRODOTTI IN VENDITA */}
                    {selectedSection === 1 && <h2>Il tuoi prodotti in vendita</h2>}
                    {selectedSection === 1 &&
                        <>
                            {reduxUserProducts && reduxUserProducts.map((product, index) => {
                                if (product.saleStatus.status === 'Available') {
                                    return <MainCard key={index} data={[product._id, product.coverImage.resizedUrl, product.title, product.category, product.price, product.gallery]} isCrud />
                                }
                            })}
                        </>
                    }

                    {/* I TUOI PRODOTTI VENDUTI */}
                    {selectedSection === 2 && <h2>I tuoi prodotti venduti</h2>}
                    {selectedSection === 2 && !noSalesMessage &&
                        swapData && swapData.sales?.map((sale, index) => {
                            return <SwapCard key={index} swapId={sale} />
                        })
                    }
                    {
                        selectedSection === 2 && noSalesMessage && <h3>{noSalesMessage}</h3>
                    }

                    {/* I TUOI PRODOTTI ACQUISTATI */}
                    {selectedSection === 3 && <h2>I tuoi prodotti acquistati</h2>}
                    {
                        selectedSection === 3 && !noPurchaseMessage &&
                        swapData && swapData.purchases?.map((purchase, index) => {
                            return <SwapCard key={index} swapId={purchase} isBuyer />
                        })
                    }
                    {
                        selectedSection === 3 && noPurchaseMessage && <h3>{noPurchaseMessage}</h3>
                    }

                </div>

            </div>


        </div>
    )
}

export default MyProfile;