import { useSelector, useDispatch } from "react-redux";
import { selectSection } from "../../redux/mySectionSlice";

const ProductsButton = ({ text, click, selectedSection }) => {
	return (
		<button className={`${selectedSection ? 'border border-teal-500 cursor-default' : 'text-slate-500 hover:text-black'}
		py-3
		rounded-lg
        w-full
        md:w-[200px]
		`}
			onClick={click}>
			{text}
		</button>
	)
};

const MyProfileNavbar = () => {
    // handle selection
    const selected = useSelector((state) => state.mySection.selected);
    const dispatch = useDispatch();
    const handleSelection = (num) => {
        dispatch(selectSection(num))
    }

    //handle alert
    const alerts = useSelector((state) => state.login.requestsAmount);
    return (
        <div className="mt-8 w-full md:w-fit flex flex-col md:flex-row md:justify-center gap-2 md:rounded-xl bg-white p-2">
            <ProductsButton text="Il tuo profilo" click={() => handleSelection(0)} selectedSection={selected === 0 ? true : false} />
            <ProductsButton text="I tuoi prodotti in vendita" click={() => handleSelection(1)} selectedSection={selected === 1 ? true : false} />
            <ProductsButton text="I tuoi prodotti venduti" click={() => handleSelection(2)} selectedSection={selected === 2 ? true : false} />
            <ProductsButton text="I tuoi prodotti acquistati" click={() => handleSelection(3)} selectedSection={selected === 3 ? true : false} />
        </div>
    )
}

export default MyProfileNavbar;